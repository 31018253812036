var u = Object.defineProperty;
var y = (d, n, t) => n in d ? u(d, n, { enumerable: !0, configurable: !0, writable: !0, value: t }) : d[n] = t;
var p = (d, n, t) => y(d, typeof n != "symbol" ? n + "" : n, t);
import { N as f } from "./chunks/NewsMediaPulseTracker.js";
import { l as v } from "./chunks/index.js";
import { setLoginFlowState as S, getUTMParams as T, fetcher as I, enhanceSalesposterResponse as L, toSlug as m } from "@vgno/utils";
const w = {
  dinepenger: "dp",
  vg: "vg"
};
class N extends HTMLElement {
  constructor() {
    super();
    p(this, "contentId");
    p(this, "contentType");
    p(this, "deviceType");
    p(this, "isApp");
    p(this, "newsroom");
    p(this, "state");
    this.contentId = this.dataset.contentId || "unknown", this.contentType = this.dataset.contentType, this.deviceType = this.dataset.deviceType, this.isApp = this.dataset.isApp === "true", this.newsroom = this.dataset.newsroom || "vg", this.state = {
      isLoading: !0,
      stripe: null
    }, this.onLoad = this.onLoad.bind(this);
  }
  constructUrl() {
    return new URL(
      `https://cm.vg.no/v2/${w[this.newsroom] || "vg"}/stripe`
    );
  }
  createLoginUrl(t) {
    var i;
    const s = new URL(window.location.href);
    s.searchParams.set("utm_source", "stripe"), t != null && t.contentName && s.searchParams.set(
      "utm_medium",
      t == null ? void 0 : t.contentName
    ), t != null && t.campaign && s.searchParams.set(
      "utm_campaign",
      t.campaign
    ), t != null && t.segmentName && s.searchParams.set(
      "utm_term",
      t.segmentName
    );
    const c = S(s.toString());
    return (i = window.Identity) == null ? void 0 : i.loginUrl({
      state: c
    });
  }
  async fetchStripeData() {
    const s = new AbortController().signal, c = await this.resolveAccount();
    if (this.isApp) {
      this.updateLoadingState(!1);
      return;
    }
    const i = T(window.location.toString(), !0), o = this.constructUrl();
    let l = "";
    try {
      const e = await c.getUser();
      l = e ? e.userId : "", l && o.searchParams.append("spid", String(l));
    } catch (e) {
      console.error("Failed to get user for paywall", e);
    }
    try {
      const e = await I(o.toString(), {
        credentials: "include",
        signal: s
      });
      if (e.status === 204) {
        this.updateLoadingState(!1);
        return;
      }
      if (e.status !== 200)
        throw new Error(`Failed to fetch stripe data: ${e.status}`);
      const a = v.createSalesposter(e, {
        contentProvider: w[this.newsroom] || "vg",
        contentType: this.contentType,
        deviceType: this.deviceType,
        paywallType: "stripe",
        contentId: this.contentId,
        document,
        window,
        utmTagOverrides: i ? {
          utm_campaign: i.campaign,
          utm_content: i.content,
          utm_medium: i.medium,
          utm_source: i.source,
          utm_term: i.terms,
          utm_url: i.url
        } : void 0,
        generateLoginUrl: (r) => this.createLoginUrl(r)
      });
      this.state.stripe = a;
    } catch (e) {
      console.error("Failed to generate stripe", e);
    } finally {
      this.updateLoadingState(!1);
    }
  }
  async onLoad(t) {
    try {
      const s = await this.resolveAccount(), c = L({
        html: t.salesposter.html,
        campaign: t.salesposter.campaign,
        clientId: s.clientId,
        env: s.env,
        utmParams: {
          utm_content: t.pulseOrigin.content,
          utm_campaign: t.pulseOrigin.campaign,
          utm_source: t.pulseOrigin.source,
          utm_medium: t.pulseOrigin.channel,
          utm_term: t.pulseOrigin.terms,
          xDomainId: t.pulseOrigin.xDomainId
        },
        statisticsId: t.data.content_id
      });
      this.shadowRoot.innerHTML = "";
      const o = new DOMParser().parseFromString(c, "text/html");
      o.querySelectorAll("style").forEach((a) => {
        this.shadowRoot.appendChild(a.cloneNode(!0));
      }), Array.from(o.body.childNodes).forEach((a) => {
        this.shadowRoot.appendChild(a.cloneNode(!0));
      }), this.shadowRoot.querySelectorAll("script").forEach((a) => {
        const r = document.createElement("script");
        a.src ? r.src = a.src : r.textContent = a.textContent, a.parentNode.replaceChild(r, a);
      }), this.trackView(), sessionStorage.removeItem("hasSession-cache");
    } catch (s) {
      console.error("Failed to load stripe", s);
    }
  }
  resolveAccount() {
    return new Promise((t) => {
      window.Identity ? t(window.Identity) : window.addEventListener("identity-initialized", () => {
        t(window.Identity);
      });
    });
  }
  async trackView() {
    var i, o, l, e, a, r, h, g;
    if (!window.pulse) {
      console.error("Pulse not found on window object");
      return;
    }
    const t = await f.fromAutotracker(
      window.pulse,
      {
        providerId: "sdrn:schibsted:client:vg",
        productName: "vgno",
        deployStage: document.documentElement.dataset.env === "development" ? "dev" : "pro"
      },
      "vg"
    ), s = document.documentElement.dataset.pageType, c = [
      "salesposter",
      m(((i = this.state.stripe) == null ? void 0 : i.salesposter.posterType) || "stripe"),
      m(((o = this.state.stripe) == null ? void 0 : o.salesposter.contentName) || "unknown"),
      (l = this.state.stripe) != null && l.salesposter.campaign ? m(this.state.stripe.salesposter.campaign) : void 0,
      (e = this.state.stripe) != null && e.salesposter.segmentName ? m(this.state.stripe.salesposter.segmentName) : void 0,
      (a = this.state.stripe) != null && a.salesposter.abTestGroup ? m(this.state.stripe.salesposter.abTestGroup) : void 0
    ].filter(Boolean);
    t.trackViewUIElement({
      origin: (r = this.state.stripe) == null ? void 0 : r.pulseOrigin,
      object: {
        name: ((h = this.state.stripe) == null ? void 0 : h.salesposter.contentName) || "Salesposter Stripe",
        elementType: ((g = this.state.stripe) == null ? void 0 : g.salesposter.posterType) || "Salesposter - Stripe",
        id: c.join(":"),
        page: {
          id: this.contentId,
          url: window.location.href,
          type: s || "Page"
        },
        placement: {
          primaryPosition: 1
        }
      }
    });
  }
  updateLoadingState(t) {
    this.state.isLoading = t, this.render();
  }
  connectedCallback() {
    this.shadowRoot || this.attachShadow({ mode: "open" }), this.fetchStripeData();
  }
  async render() {
    !this.state.stripe || this.state.isLoading || this.isApp || await this.onLoad(this.state.stripe);
  }
}
const A = () => {
  customElements.get("vg-stripe") || customElements.define("vg-stripe", N);
};
export {
  A as default
};
